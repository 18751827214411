<template>
    <div>
        <Row class="paper" type="flex" justify="center">
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/paper/p-1.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <div>With origami as the inspiration behind several styles, these imaginative fashions have been shown on runways and in magazines alike, demonstrating how intricately constructed styles can be made from one of the simplest of materials. The garments are both elegant and unexpected in the way they fold, bend and mold an everyday item into something sublime.</div>
                <div>I use origami-based concepts to create voluminous dresses that recall the artisanal methods for creating pleats.</div>
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/paper/p-2.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/paper/p-3.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/paper/p-4.jpg" alt="">
            </Col>
            <Col :span="22" :md="18" :lg="18">
                <img src="@img/paper/p-5.jpg" alt="">
            </Col>
        </Row>
        <copy-right />
    </div>
</template>

<script>
import common from '@M/common';
import CopyRight from '@C/CopyRight';

export default {
    name: 'Paper',
    mixins: [common],
    components: {
        CopyRight
    }
}
</script>

<style lang="scss">
.paper {
    flex-direction: column;
    align-items: center;
    > div {
        max-width: 800px;
        margin-bottom: 2rem;
        color: #000;
        font-size: 13px;
        line-height: 2em;

        &:first-child {
            margin-top: 5rem;
        }
        img {
            width: 100%;
            height: inherit;
        }
    }
}
</style>
